import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import Block from '../types';
import {Normalized} from "@/shared/lib/normalize";

@singleton()
export class BlockMiniStore {
  miniBlocks: Normalized<Block>;

  constructor() {
    this.miniBlocks = null;

    makeAutoObservable(this);
  }
}
