import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { Box, Stack } from "@mui/material";

import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { refreshOptions } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/constants";
import { DataCounterStatistic } from "@/entities/Dashboard/types";

const periodService = container.resolve(PeriodService);
const counterService = container.resolve(CounterService);
const dashboardService = container.resolve(DashboardService);

export interface CounterPanelProps {
  dashboardPanelId: string;
}

const getRefreshTimeout = (key: string) => {
  const opt = refreshOptions.find((itm) => itm.key === key);
  return opt?.value;
}

export const defaultPanelData: DataCounterStatistic = {
  messageType: 'all',
  blockIdList: [],
  flowId: "",
};

function NumberAlone ({ color = 'gray', num = 0 }) {
  return (
    <Box
      width="100%"
      height="100%"
      minWidth={160}
      display="flex"
      justifyContent="space-around"
    >
      <svg viewBox="0 0 100 50">
        <text
          x="50%"
          y="90%"
          style={{
            textAnchor: 'middle',
            fill: color,
            fontSize: '3em',
          }}
        >
          {num && num}
        </text>
      </svg>
    </Box>
  );
}

function Number ({ color = 'gray', num = 0 }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 70 70"
    >
      <text
        x="50%"
        y="60%"
        style={{
          textAnchor: 'middle',
          fill: color,
        }}
      >
        {num && num}
      </text>
    </svg>
  );
}

const colorMap: { [key: string]: string } = {
  started: 'gray',
  errors: 'red',
  success: 'green',
};

export const CounterStatistic = observer((props: CounterPanelProps) => {
  const { dashboardPanelId } = props;
  const { get } = useSearchParamsTemplate();
  const refresh = get('refresh');
  const { period } = periodService;
  const { mode } = periodService;

  const panelData = dashboardService.getPanelData(dashboardPanelId);
  const { messageType = 'all', blockIdList } = panelData || defaultPanelData;

  const dataWatcher = blockIdList.slice().sort().join();
  useEffect(() => {
    counterService.getCounterStatistic(blockIdList, periodService.unixPeriod);

    if (!refresh) return undefined;

    const timeout = getRefreshTimeout(refresh) * 1000; // seconds
    if (!timeout || mode !== 'live') return undefined;

    const id = setInterval(() => {
      if (periodService.mode === 'history') {
        clearInterval(id);
        return;
      }

      counterService.getCounterStatistic(blockIdList, periodService.unixPeriod);
    }, timeout);
    return () => clearInterval(id);
  }, [mode, dataWatcher, refresh, period]);

  const statistic = counterService.getStatisticBlocks(blockIdList) as { [key: string]: number };

  if (messageType !== 'all') {
    return (
      <NumberAlone
        color={colorMap[messageType]}
        num={statistic[messageType]}
      />
    )
  }

  return (
    <Stack
      height="100%"
      minWidth={150}
      style={{
        fontSize: '3em',
      }}
    >
      <Box
        width="50%"
        height="50%"
        alignSelf="center"
      >
        <Number num={statistic.started} />
      </Box>
      <Stack
        height="50%"
        width="100%"
        direction="row"
        alignItems="stretch"
      >
        <Box
          width="50%"
          height="100%"
        >
          <Number color="green" num={statistic.success} />
        </Box>
        <Box
          width="50%"
          height="100%"
        >
          <Number color="red" num={statistic.errors} />
        </Box>
      </Stack>
    </Stack>
  )
});
