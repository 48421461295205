import React from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import MenuItem from '@mui/material/MenuItem';
import { Input } from '@/shared/ui';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { panelTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles"

import { Settings as CounterPanelSettings } from '../CounterPanel';
import { Settings as StatisticSettings } from '../CounterStatistic';

import styles from './FormSettings.module.scss';

const dashboardService = container.resolve(DashboardService);

export interface DashboardPanelSettingsProps {
  dashboardPanelId: string;
}

export const FormSettings = observer(({
    dashboardPanelId,
  }: DashboardPanelSettingsProps) => {
    const panel = dashboardService.getPanel(dashboardPanelId);

    const onChangeType = (newType: string) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { type: newType, data: null })
    };

    const onChangeName = (newName: string) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { name: newName })
    };

    const renderSelectType = () => (
      <Input
        select
        label={`Тип ${panelTitles.gen}`}
        value={panel.type}
        SelectProps={{
          value: panel.type,
          onChange: (event) => {
            onChangeType(event.target.value as string);
          },
        }}
      >
        <MenuItem key="CounterPanel" value="CounterPanel">
          График работы блока
        </MenuItem>
        <MenuItem key="CounterStatistic" value="CounterStatistic">
          Статистика по потоку
        </MenuItem>
      </Input>
    );

    const renderSettingsByType = () => {
      switch (panel.type) {
        case "CounterPanel": {
          return <CounterPanelSettings dashboardPanelId={dashboardPanelId} />
        }
        case "CounterStatistic": {
          return <StatisticSettings dashboardPanelId={dashboardPanelId} />
        }
        default: {
          return null;
        }
      }
    };

    return (
      <div>
        <div className={styles.root}>
          <Input
            label={`Наименование ${panelTitles.gen}`}
            value={panel.name}
            onChange={(event) => {
              onChangeName(event.target.value);
            }}
          />
          {renderSelectType()}
          {renderSettingsByType()}
        </div>
      </div>
    )
  },
)
